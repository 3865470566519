@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.commonWidth {
  max-width: 95%;
  width: 100%;
  margin: auto;
  padding: 50px 0;
}

.commonTitleFontSize {
  font-size: 2.3rem !important;
  color: #000;
  font-weight: 700;
  padding: 1rem 0rem;
}

p {
  font-size: 1rem !important;
  color: rgb(100, 98, 98) !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  z-index: 9999;
  /* position: fixed; */
}

.header.active {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
}

.header__logo a {
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
}

.header__logo img {
  width: 108px;
}

.header__nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  gap: 30px;
  padding: 0;
}

.header__nav li {
  margin-right: 20px;
}

.header__nav a {
  color: #384baf;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 600;
}

.header__nav li .active {
  border-bottom: 1px solid #384baf;
}

.header__toggle {
  border: none;
  cursor: pointer;
  display: none;
}

.header__toggle-icon {
  display: block;
  position: absolute;
  right: 6px;
  top: 26px;
}

.show_mobile {
  display: none;
}

.hamburger-menu {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: space-between;
  padding: 5px;
  width: 30px;
}

.hamburger-menu-line {
  background-color: #333;
  height: 2px;
  width: 100%;
}

.Krushna53-Con-button button {
  color: white;
  padding: 10px 20px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  background: #ffb727;
}

.Krushna53-Con-button button a {
  color: white;
}

/* banner css */
.Krushna53-banner {
  min-height: auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Krushna53-banner h1 {
  padding-bottom: 2rem;
  font-size: 4rem;
  color: #000;
}

.Krushna53-banner p {
  padding-bottom: 2rem;
  font-size: 1.5rem;
}

.Krushna53-Con-button button {
  color: white;
  padding: 10px 20px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  background: #384baf;
}

button:hover {
  background: #ffb727;
}

.Krushna53-banner-img img {
  max-width: 40rem;
  height: auto;
  /* border-radius: 100%; */
}

/* about css */
.Krushna53-AboutUs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Krushna53-AboutUs-flex .AboutUs-info {
  width: 635px;
  height: auto;
  color: #000;
}

.AboutUs-info h3 {
  font-size: 20px;
  margin-bottom: 9px;
}

.AboutUs-info p {
  font-size: 19px;
  line-height: 38px;
  text-align: justify;
  /* margin-bottom: 20px; */
}

.blank-img {
  max-width: 490px;
  height: auto;
  border-radius: 5px;
  aspect-ratio: 6/5;
  object-fit: contain;
}

.read-more {
  text-decoration: none;
  color: blue;
  font-size: 24px;
}

.Technologies .d_flex {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  /* padding: 40px 0; */
}

.technologies_text {
  text-align: center;
}

.Technologies .wrapper {
  width: 245px;
  margin: 12px 0px;
  text-align: center;
}

section.team,
.team .title,
.team .team_image {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}

.team .container {
  padding: 20px;
  max-width: 100%;
  height: auto;
}

.team .title {
  margin-top: 20px;
  font-size: 20px;
  font-family: Inter, sans-serif;
  font-weight: 700;
}

.team .team_image img {
  width: 100%;
  height: 200px;
}

.team .team_image img {
  border-radius: 50%;
  width: 200px;
  margin: 30px;
}

.caption {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.caption a {
  text-decoration: none;
}

.caption i {
  font-size: 28px;
  padding: 8px 11px;
}

.d_flex svg {
  width: 100px;
  height: 100px;
  /* border-radius: 50px; */
  /* border: 0.1px solid #0000001f; */
  padding: 9px;
  border-radius: 20px;
  margin: auto;
}

.slick-active.slick-current svg {
  border: 0.1px solid #00000077;
  zoom: 2;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.slick-prev {
  left: -22px !important;
}

.slick-next {
  right: -22px !important;
}

.form-control {
  padding: 0.475rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.1;
}

.php-email-form button[type="submit"] {
  background: #384baf;
  border: 0;
  border-radius: 5px;
  color: #fff;
  padding: 10px 24px;
  transition: 0.4s;
}

.contact_text {
  text-align: center;
}

.team_image p {
  padding: 0px;
  margin: 0;
}

.Krushna53-Clients h2 {
  font-size: 28px;
  text-align: center;
}

.Krushna53-gallery h2 {
  text-align: center;
  margin: 55px 0px;
  font-size: 28px;
}

.logo-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.logo-section img {
  width: 100%;
  aspect-ratio: 6/1;
  object-fit: contain;
  height: 50%;
  filter: grayscale(100%);
  transition: 0.3s;
}

.image-folder img {
  width: 100%;
  margin: auto;
  height: 130px;
  object-fit: contain;
}

.slick-track {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

/* service css */

.service-text {
  text-align: center;
  font-size: 40px;
}

.service-blog {
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  align-items: center;
  justify-content: space-between;
}

.blog-image img {
  width: 100%;
  height: auto;
  /* margin-bottom: 20px; */
  aspect-ratio: 2/2;
  object-fit: contain;
}

.service-blog p {
  text-align: justify;
}

.blog-image h2 {
  font-size: 25px;
}

.blog-image {
  margin: 5px 10px;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  width: 340px;
}

.blog-image p {
  text-align: justify;
}

.read-more {
  text-decoration: none;
  color: blue;
  font-size: 24px;
}

.footer-icon {
  text-decoration: none;
  margin: auto;
}

.ri-facebook-circle-fill {
  animation-delay: 1s;
}

.ri-instagram-line {
  animation-delay: 2s;
}

.ri-linkedin-box-fill icon {
  animation-delay: 3s;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

.Krushna53-Testimonials {
  min-height: auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.Krushna53-Testimonials-img {
  max-width: 40%;
}

.Krushna53-Testimonials-paragraph {
  max-width: 655px;
}

.Krushna53-Testimonials,
.Krushna53-Testimonials-img img {
  align-items: center;
  margin: 70px 0px;
}

.Krushna53-Testimonials h1 {
  padding-bottom: 2rem;
  font-size: 2rem;
  color: #000;
}

.Krushna53-Testimonials p {
  padding-bottom: 2rem;
  font-size: 1.5rem;
}

.Krushna53-Testimonials-img img {
  max-width: 400px;
  height: auto;
  border-radius: 100%;
  margin-left: 50px;
}

.Krushna53-Con-button.hide-mobile {
  display: none;
}

.contact-form-container button[type="submit"]:hover {
  background: #ffb727;
}

.Technologies .slick-slider {
  overflow: hidden;
  cursor: pointer;
}

.some_work {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.work_main {
  max-width: 100%;
  width: 457px;
  height: 410px;
}

.some_work .image {
  display: block;
  overflow: hidden;
}

.some_work img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out 0s;
}

.some_work .image img:hover {
  transform: scale(1.1);
}

.page {
  margin-top: 15px;
}

.page h4 {
  font-weight: 700;
  font-size: 25px;
  color: #1f1f1f;
  font-family: space-grotesk-bold, sans-serif;
  margin: 15px auto;
}

.some_work a {
  text-decoration: none;
  cursor: pointer;
}

.page a {
  color: #767676;
  font-family: space-grotesk-bold, sans-serif;
  line-height: 1;
  font-weight: 700;
  text-decoration: underline;
}

.flex p {
  width: 83%;
  margin: auto;
  text-align: justify;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 0.1fr);
  grid-gap: 20px;
  text-align: center;
  justify-content: center;
}

.image-folder {
  border: 1px solid #e0e5e4;
  width: 335px;
  margin: 10px auto;
  text-align: center;
  padding: 0.6em 1.2em;
  box-shadow: -1px 4px 19px -5px #e0e5e4;
}

#Clients p {
  text-align: center;
}

/* some of work details css  */
section.SomeOfOurWorkDetails {
  margin-top: 65px;
}

section.SomeOfOurWorkDetails .left {
  width: 620px;
}

.title h2,
.left h4,
.subhedding,
.overview h4 {
  font-family: space-grotesk-bold, sans-serif;
}

.title h2 {
  margin-top: 10px;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.tag,
.left p,
.overview p,
ul {
  font-family: "Helvetica-Now-Display", sans-serif;
  text-align: justify;
}

.tag h5 {
  border: none;
  box-sizing: border-box;
  box-shadow: 0.1px 0.1px 2px 0.1px rgb(100 98 128);
  color: #646262;
  text-align: center;
  font-size: 17px;
  padding: 0.2em;
}

.tag {
  display: flex;
  align-items: center;
  margin: auto;
  gap: 18px;
  padding: 30px 0;
}

.banner_image img {
  width: 100%;
  height: auto;
}

.text_image,
.description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 50px 0;
}

.left h4 {
  margin: 10px 0;
  font-size: 30px;
  font-weight: 700;
  color: #1f1f1f;
}

.left p {
  color: #1f1f1f;
  line-height: 1.6;
}

.right {
  width: 550px;
  height: 100%;
}

.right img {
  width: 100%;
  height: auto;
  margin: auto;
}

.subhedding {
  font-size: 20px;
  font-weight: 700;
  color: #1f1f1f;
  line-height: 1.5;
}

.description {
  gap: 30px;
}

.overview {
  max-width: 700px;
}

.overview h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.overview p {
  margin: 10px 0;
  color: #1f1f1f;
  font-weight: 400;
  line-height: 1.5;
}

.overview li {
  margin-top: 10px;
  list-style: circle;
  color: #1f1f1f;
}

.banner_image video {
  width: 100%;
  height: 400px;
}
/* CareersPage */

.job_openings .title h2 {
  font-size: 2.3rem;
  font-weight: 700;
  text-align: center;
}
.job_fild {
  display: flex;
  justify-content: center;
  gap: 10%;
}
.fild_card {
  width: 50%;
  margin: auto;
  padding-top: 70px;
}
.fild_card .d-flex {
  gap: 3%;
}
.fild_card h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  width: 66%;
}
.fild_card h5 {
  font-size: 1rem;
  font-weight: 600;
  color: #646262;
}
.fild_card a.read-more {
  padding: 9px 10px;
  background: #384baf;
  color: white;
  border: none;
  font-weight: 500;
  font-size: 1rem;
}
/* JobDetails */
.JobDetails {
  text-align: justify;
  padding: 50px 0px;
}

.JobDetails h1 {
  font-size: 2.3rem;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-top: 70px;
}
.JobDetails .fild_item h2 {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 20px 0px;
}
.JobDetails .fild_text h5 {
  font-weight: 600;
  margin: 5px;
}
.JobDetails .fild_text span {
  color: #000;
  font-weight: bold;
}

@media only screen and (min-width: 1400px) {
  .service-blog {
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .read-more {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 40px;
  }


  .blank-img {
    max-width: 390px;
  }

  .AboutUs-info p {
    line-height: 30px;
  }

  .Krushna53-Testimonials-img {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .Krushna53-Testimonials-img img {
    max-width: 363px;
    margin-left: 0px;
  }

  .Krushna53-Testimonials p {
    margin: 0px 0px 0px 15px;
  }

  .Krushna53-AboutUs-flex .AboutUs-info {
    max-width: 100%;
  }

  .aboutus-image {
    display: flex;
  }

  .Krushna53-Testimonials {
    display: block !important;
    max-width: 100%;
  }

  .Krushna53-Testimonials-paragraph {
    margin: auto;
    text-align: center;
  }

  .show-btn {
    display: block;
    font-size: 60px;
    color: black;
    position: absolute;
    top: 0;
    right: 5%;
    z-index: 4;
  }

  .show-btn i {
    font-size: 40px;
  }

  ul li a {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 768px) {
  header {
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: block;
    z-index: 3;
  }

  .Krushna53-Con-button.show-mobile {
    display: none;
  }

  header ul {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    z-index: 99;
  }
  .service-blog {
    grid-template-columns: repeat(1, 1fr);
  }
  .content .rich-text .box {
    width: 100%;
  }

  .read-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .footerwrapper {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .logo {
    margin: 20px 0px 10px 0px;
  }

  .Krushna53-header .Krushna53-Con-button {
    margin: 2rem 2rem 2rem 2rem;
  }

  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Krushna53-AboutUs-flex .AboutUs-info {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    text-align: center;
  }

  .blank-img {
    max-width: 543px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  .Krushna53-AboutUs-flex {
    display: block;
  }

  .AboutUs-info h2 {
    font-size: 35px;
    margin-top: 25px;
  }

  .AboutUs-info h3,
  .AboutUs-info p {
    font-size: 25px;
  }

  .Krushna53-banner-img {
    display: flex;
    align-items: center;
    margin-right: 18px;
  }

  .Krushna53-logo img {
    max-width: 254px;
  }

  .Krushna53-navbar ul li a {
    font-size: 3rem;
  }
  .Krushna53-header .Krushna53-Con-button button {
    padding: 2rem;
    font-size: 2rem;
  }

  #Top-btn {
    width: 52px !important;
    height: 52px !important;
    font-size: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    left: 0;
    top: 10px;
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    width: 100%;
    height: 100vh;
    background: #fff;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    background: none;
  }

  .Krushna53-header {
    z-index: 4 !important;
  }

  .Krushna53-banner {
    flex-direction: column;
  }

  .Krushna53-paragraph {
    max-width: 100%;
    padding: 0px;
  }

  .Krushna53-banner-img img {
    max-width: 100%;
    margin: 0px;
  }

  .Krushna53-banner-img {
    min-width: 100%;
  }

  .Krushna53-AboutUs-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .blank-img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .logo-section {
    display: block;
    margin: auto;
    max-width: 100%;
    text-align: center;
    padding: 0px;
  }

  .image-folder {
    padding: 0.6rem 0.5rem;
    width: 100%;
  }

  .image-folder img {
    /* max-width: 100%; */
    width: 100%;
  }
  .footerwrapper {
    padding: 0px;
  }

  .Krushna53-Testimonials-img {
    max-width: 100%;
  }

  .Krushna53-Testimonials-img img {
    max-width: 100%;
    margin-left: 0px;
  }

  .service-text {
    font-size: 48px;
  }

  .blog-image img {
    max-width: 100%;
    height: 100%;
  }
  .footerwrapper p {
    font-size: 22px;
  }

  .slick-prev {
    left: 20px !important;
    color: #fff !important;
  }

  .slick-next {
    right: 21px !important;
  }

  .Krushna53-Testimonials p {
    margin: 0px 0px 0px 0px;
  }

  .contact .d_flex {
    display: block;
  }

  .contact iframe {
    width: 100%;
  }

  .blog-image {
    width: 100%;
  }

  .Technologies .wrapper {
    width: auto;
  }

  .AboutUs-info p {
    text-align: justify;
  }

  .Krushna53-AboutUs h2 {
    text-align: center;
  }

  .commonWidth {
    padding: 20px 0;
  }

  .blog-image {
    margin: 0;
  }

  .header {
    align-items: flex-start;
    flex-direction: column;
    position: relative;
  }

  .nav_new {
    margin: auto;
  }

  .header__nav {
    display: none;
    width: 100%;
  }

  .header__nav ul {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .header__nav li {
    margin: 10px 0;
  }

  .header__toggle,
  .show_mobile,
  .header__nav.open {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  form.php-email-form {
    margin-bottom: 20px;
  }

  .slider_one .slick-prev,
  .slider_one .slick-next {
    display: none !important;
  }

  .some_work {
    display: block;
  }

  .work {
    margin: 14px 0px;
  }

  .page h4 {
    margin: 5px auto;
  }

  .header.active {
    position: sticky;
  }

  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .text_image,
  .description {
    flex-direction: column;
  }

  .title {
    margin: 20px 0;
  }

  .right img,
  .right {
    width: 100%;
    margin: auto;
  }

  .right {
    max-width: 360px;
  }

  .text_image {
    gap: 20px;
    padding: 20px 0;
  }

  .overview {
    margin-top: 20px;
    max-width: 100%;
  }

  section.SomeOfOurWorkDetails .left {
    width: 100%;
  }

  section.SomeOfOurWorkDetails .slick-prev {
    left: -23px !important;
  }

  section.SomeOfOurWorkDetails .slick-next {
    right: -23px !important;
  }

  section.SomeOfOurWorkDetails {
    margin: 0px;
  }
  .job_fild {
    flex-direction: column;
  }
  .fild_img img {
    width: 100%;
  }
  .job_openings .fild_card {
    width: 100%;
  }
  .fild_card a.read-more {
    width: 40%;
    margin: 0px;
  }
  .blog-image img {
    aspect-ratio: 3 / 2.3;
  
}
}
.Krushna53-Clients p {
  text-align: center;
}
